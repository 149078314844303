import React from "react";

function Horeb() {
  return (
    <section id="page-content" data-aos="fade-up" data-aos-duration="1000">
      <div className="banner-2nd-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12 content-details">
              <div className="main-content text-center">
                <div className="page-details">
                  <p data-aos="fade-up">
                    Horeb, the trailblazer of India's spice scene, <br />
                    With exotic flavors that tantalize the tongue, <br />
                    Their masalas are a legacy that's second to none.
                  </p>

                  <p data-aos="fade-up">
                    Each spice is carefully chosen to satiate the soul,
                    <br />A symphony of tastes that make your senses whole.
                  </p>

                  <p data-aos="fade-up">
                    For those who seek a taste that's truly divine,
                    <br />
                    Horeb's masalas are the perfect find.
                  </p>

                  <p data-aos="fade-up">
                    So come and savor the flavors of India's best,
                    <br />
                    With Horeb, your taste buds will be truly blessed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Horeb;
