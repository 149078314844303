import React from "react";
import Header from "./Common/Header";
import Hero from "./Home/Hero";
import Horeb from "./Home/Horeb";
import Farmer from "./Home/Farmer";
import ProductRange from "./Home/ProductRange";

function Home() {
  return (
    <section  className="banner-bg">
      <Header />
      <Hero />
      <Horeb />
      <Farmer />
      <ProductRange />
    </section>
  );
}

export default Home;
