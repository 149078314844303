import React from "react";
import { Link } from "react-router-dom";

function Page404() {
  return (
    <div
      className="container-fluid d-flex align-items-center justify-content-center"
      style={{ backgroundColor: "black", height: "100vh" }}
    >
      <div
        className="row d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <div className="col-12 text-center text-white">
          <h1 className="display-4">404 - Page Not Found</h1>
          <p className="lead">Oops! Looks like you're lost.</p>
          <p>
            Please navigate back to the{" "}
            <Link to="/" className="text-white">
              <button className="btn btn-light" style={{ borderRadius: '50px' }}>homepage</button>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Page404;
