import React from "react";
import Header from "../../components/Common/Header";

function Contact() {
  return (
    <section className="contactUs">
      <div id="blend-masalas">
        <div className="blend-masalas-bg">
          <Header />
          <div class="container blend-masalas-product">
            <div class="blend-page-title text-center" data-aos="fade-up">
              <h2>Contact Us</h2>
              <p>Our Branch</p>
              {/* <div className="row">
                <div className="col-md-4 col-12">
                  <h1>Canada:</h1>
                </div>
                <div className="col-md-4 col-12">
                  <h1>India:</h1>
                </div>
                <div className="col-md-4 col-12">
                  <h1>South Africa:</h1>
                </div>
              </div> */}
            </div>
          </div>
          <div className="container contact_address_p">
            <div className="row">
              <div className="col-md-4 col-12" data-aos="fade-up">
                <h4 style={{ color: "#e7b900" }}>Canada:</h4>
                <p>
                  <b>HOREB BUSINESS SOLUTIONS INC,</b> <br />
                  1325 Tudor Crescent, Peterborough, ON Canada K9L1X1 <br />
                  <br />
                  <strong>Phone:</strong> +1 3433336317 <br />
                  <strong>Email:</strong> &nbsp; care@horebfoods.com <br />
                </p>
              </div>
              <div className="col-md-4 col-12" data-aos="fade-up">
                <h4 style={{ color: "#e7b900" }}>India:</h4>
                <p>
                  <b>M/S. HOREB TRADING & EXPORTS PVT LTD,</b> <br />
                  No: 3/300-7, Kalingapatti road,
                  <br /> Parankuntrapuram B.O <br />
                  V.K. Pudhur, Tirunelveli -627859,<br></br>
                  Tamil Nadu, India <br />
                  <br />
                  <strong>Phone:</strong> +91 8056569589, +91 8870844506 <br />
                  <strong>Email:</strong> &nbsp; care@horebfoods.com <br />
                </p>
              </div>
              <div className="col-md-4 col-12" data-aos="fade-up">
                <h4 style={{ color: "#e7b900" }}>South Africa:</h4>
                <p>
                  <b> HOREB FOOD PROCESSING AND DISTRIBUTORS PVT LTD,</b> <br />
                  Section 26, Door 105, <br />
                  48, Van Riebeeck Avenue, <br />
                  KG Centre, 1 Floor Alberton Central, <br />
                  Alberton, Gauteng, South Africa 1449 <br />
                  <br />
                  <strong>Phone:</strong> +27 10 216 9318, +27 794570601 <br />
                  <strong>Email:</strong> &nbsp; care@horebfoods.com <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
