import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import "./style/custom.css";
import Footer from "./components/Common/Footer";
import BlendMasala from "./components/products/BlendMasala";
import Daals from "./components/products/Daals";
import GroundSpices from "./components/products/GroundSpices";
import Rice from "./components/products/Rice";
import Spices from "./components/products/Spices";
import Recipes from "./components/Recipes";
import Page404 from "./components/Common/Page404";
import About from "./pages/AboutUs/About";
import Contact from "./pages/ContactUs/Contact";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/blendmasala" element={<BlendMasala />} />
        <Route path="/products/daals" element={<Daals />} />
        <Route path="/products/groundspices" element={<GroundSpices />} />
        <Route path="/products/rice" element={<Rice />} />
        <Route path="/products/spices" element={<Spices />} />
        <Route path="/recipes" element={<Recipes />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
