import React from "react";
import Header from "./Common/Header";
import Recipe from "../../src/assets/Image/receipe.webp";

function Recipes() {
  return (
    <div id="blend-masalas">
      <div className="blend-masalas-bg">
        <div className="container-fluid">
          <Header />
          {/* <div
            className="container blend-masalas-product d-flex align-items-center justify-content-center"
            style={{ minHeight: "60vh" }}
          >
            <h1 style={{ color: "orange" }}>UNDER CONSTRUCTION</h1>
          </div> */}
          <div className="d-flex justify-content-center">
            <img src={Recipe} alt="recipe" style={{ width: "100%" }}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recipes;
