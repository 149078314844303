import React from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaHome, FaReceipt, FaAddressCard } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import logo from "../../assets/Image/logo.png";

function Header() {
  return (
    <section style={{ backgroundColor: "transparent" }}>
      <header id="Landing-page">
        <Container>
          <Navbar expand="lg" variant="dark" className="justify-content-lg-end">
            <Navbar.Brand as={Link} to="/">
              <img src={logo} alt="Logo" width={100} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link as={Link} to="/" className="nav-link">
                  Home <FaHome />
                </Nav.Link>
                <NavDropdown title={<>Products</>} id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/products/blendmasala">
                    Blend Masalas
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/products/daals">
                    Daals
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/products/groundspices">
                    Ground Spices
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/products/rice">
                    Rice
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/products/spices">
                    Spices
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/recipes" className="nav-link">
                  Recipes <FaReceipt />
                </Nav.Link>
                <Nav.Link as={Link} to="/about" className="nav-link">
                  About Us <FaAddressCard />
                </Nav.Link>
                <Nav.Link as={Link} to="/contact" className="nav-link">
                  Contact Us <MdCall />
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
    </section>
  );
}

export default Header;
