import React from "react";
import { Link } from "react-router-dom";

function ProductRange() {
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section id="product-page" data-aos="fade-up" data-aos-duration="1000">
      <div className="bg-4th-page">
        <div className="content-4th-page">
          <h1 className="product-page-title" data-aos="fade-up">
            <span>Product Range</span>
          </h1>
          <div className="products-name mt-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-1 col-12"></div>
                <div
                  className="col-md-2 col-12 d-flex align-items-center justify-content-center"
                  data-aos="fade-up"
                >
                  <Link to="/products/spices" onClick={handleScroll}>
                    Spices
                  </Link>
                </div>
                <div
                  className="col-md-2 col-12 d-flex align-items-center justify-content-center"
                  data-aos="fade-up"
                >
                  <Link to="/products/groundspices" onClick={handleScroll}>
                    Ground Spices
                  </Link>
                </div>
                <div
                  className="col-md-2 col-12 d-flex align-items-center justify-content-center"
                  data-aos="fade-up"
                >
                  <Link to="/products/blendmasala" onClick={handleScroll}>
                    Masalas
                  </Link>
                </div>
                <div
                  className="col-md-2 col-12 d-flex align-items-center justify-content-center"
                  data-aos="fade-up"
                >
                  <Link to="/products/rice" onClick={handleScroll}>
                    Rice
                  </Link>
                </div>
                <div
                  className="col-md-2 col-12 d-flex align-items-center justify-content-center"
                  data-aos="fade-up"
                >
                  <Link to="/products/daals" onClick={handleScroll}>
                    Daals
                  </Link>
                </div>
                <div className="col-md-1 col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductRange;
