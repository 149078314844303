import React from "react";
import Header from "../../components/Common/Header";

function About() {
  return (
    <section className="about_us">
      <div id="blend-masalas">
        <div className="blend-masalas-bg">
          <Header />
          <div class="container blend-masalas-product">
            <div class="blend-page-title text-center">
              <h2 data-aos="fade-up">About Us</h2>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="about-us">
                  <p data-aos="fade-up">
                    {" "}
                    Welcome to Horeb Spices, where we bring the authentic taste
                    of India to your kitchen. Our passion for spices began with
                    a desire to share the flavours of our homeland with the
                    world. Today, we are proud to be one of the leading FMCG
                    Indian spices companies, exporting our products to Canada
                    and South Africa.
                  </p>

                  <p data-aos="fade-up">
                    {" "}
                    Our journey started with a commitment to quality. We source
                    our spices from the best farmers in India, who use
                    traditional methods to grow and harvest their crops. Our
                    manufacturing facility in Tamil Nadu, India, is equipped
                    with state-of-the-art technology to ensure that our spices
                    meet the highest standards of purity and quality.
                  </p>

                  <p data-aos="fade-up">
                    {" "}
                    At Horeb Spices, we believe that the secret to a great meal
                    lies in the quality of the ingredients. That's why we take
                    great care in selecting and blending our spices to bring out
                    the unique flavors and aromas of Indian cuisine. Our range
                    of spices includes cumin, coriander, black pepper, turmeric,
                    cardamom, cinnamon, blend masalas and more.
                  </p>

                  <p data-aos="fade-up">
                    {" "}
                    We are committed to sustainability and social
                    responsibility. We work closely with our farmers to promote
                    sustainable farming practices and provide them with fair
                    prices for their crops. We believe in building long-term
                    relationships with our customers, and we are dedicated to
                    providing the best service and support possible.
                  </p>

                  <p data-aos="fade-up">
                    {" "}
                    Thank you for choosing Horeb Spices. We are excited to share
                    the authentic taste of India with you and your family.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
