import React, { useState } from "react";
import Header from "../Common/Header";
import ImageModal from "../Common/ImageModel";

function Daals() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(""); // State to store the selected title

  const handleShowModal = (images, title) => {
    // Update handleShowModal to accept title
    setSelectedImages(images);
    setSelectedTitle(title); // Set the selected title
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImages([]);
    setSelectedTitle("");
    setShowModal(false);
  };

  const data = [
    {
      id: 1,
      title: "Besan Flour",
      images: ["/Daals/Besan Flour Front.webp", "/Daals/Besan Flour back.webp"],
    },
    {
      id: 2,
      title: "Chana Dall",
      images: ["/Daals/Chana Dall front.webp", "/Daals/Chana Dall back.webp"],
    },
    {
      id: 3,
      title: "Greengram",
      images: ["/Daals/greengram front.webp", "/Daals/greengram back.webp"],
    },
    {
      id: 4,
      title: "Masoor Red Lentils",
      images: [
        "/Daals/Masoor red lentilsfront.webp",
        "/Daals/Masoor red lentils back.webp",
      ],
    },
    {
      id: 5,
      title: "Moog Dall",
      images: [
        "/Daals/moong dal split front.webp",
        "/Daals/moong dal split back.webp",
      ],
    },
    {
      id: 6,
      title: "Red Kidney Bean",
      images: [
        "/Daals/red kidney bean Front.webp",
        "/Daals/red kidney bean back.webp",
      ],
    },
    {
      id: 7,
      title: "Toor Dall",
      images: ["/Daals/toor dall Front.webp", "/Daals/toor dall back.webp"],
    },
    {
      id: 8,
      title: "Urad Dall",
      images: ["/Daals/urad dal split front.webp", "/Daals/urad dal split back.webp"],
    },
    {
      id: 9,
      title: "Yellow Moong Dal",
      images: ["/Daals/yellow lentils Front.webp", "/Daals/yellow lentils back.webp"],
    },
    // Add more items here with their respective images and titles
  ];

  return (
    <div id="blend-masalas">
      <div className="blend-masalas-bg">
        <div className="container-fluid">
          <Header />
          <div className="container blend-masalas-product">
            <div className="blend-page-title text-center">
              <h2 className="h2-large">Daals</h2>
            </div>
            <div className="row">
              {data.map((item) => (
                <div
                  key={item.id}
                  className="col-md-3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div
                    className="card blend-masalas-content"
                    onClick={() => handleShowModal(item.images, item.title)} // Pass title to handleShowModal
                  >
                    <img
                      className="primary-image"
                      src={item.images[0]} // Display only the first image
                      alt={item.title}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{item.title}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ImageModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedImages={selectedImages}
        selectedTitle={selectedTitle} // Pass selectedTitle to ImageModal
      />
    </div>
  );
}

export default Daals;
