import React from "react";
import { Modal } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function ImageModal({
  showModal,
  handleCloseModal,
  selectedImages,
  selectedTitle,
}) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{selectedTitle}</Modal.Title> {/* Display selectedTitle */}
      </Modal.Header>
      <Modal.Body>
        <Carousel
          responsive={responsive}
          arrows={true}
          slidesToSlide={1}
          swipeable={true}
        >
          {selectedImages.map((image, index) => (
            <div
              key={index}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={image}
                alt={`Selected ${index}`}
                className="img-fluid"
              />
            </div>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}

export default ImageModal;
