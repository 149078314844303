import React, { useState } from "react";
import Header from "../Common/Header";
import ImageModal from "../Common/ImageModel";

function Rice() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(""); // State to store the selected title

  const handleShowModal = (images, title) => {
    // Update handleShowModal to accept title
    setSelectedImages(images);
    setSelectedTitle(title); // Set the selected title
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImages([]);
    setSelectedTitle("");
    setShowModal(false);
  };

  const data = [
    {
      id: 1,
      title: "Basmati Rice",
      images: ["/Rice/Basmati Rice front.webp", "/Rice/Basmati Rice back.webp"],
    },
    {
      id: 2,
      title: "Idly Rice",
      images: ["/Rice/Idly Rice front.webp", "/Rice/Idly Rice back.webp"],
    },
    {
      id: 3,
      title: "Matta Rice",
      images: ["/Rice/Matta Rice front.webp", "/Rice/Matta Rice back.webp"],
    },
    {
      id: 4,
      title: "Parboiled Rice",
      images: ["/Rice/Parboiled Rice front.webp", "/Rice/Parboiled Rice back.webp"],
    },
    {
      id: 5,
      title: "Ponni Rice",
      images: ["/Rice/Ponni Rice front.webp", "/Rice/Ponni Rice back.webp"],
    },
    {
      id: 6,
      title: "Sona Masoori Rice",
      images: [
        "/Rice/Sona Masoori Rice front.webp",
        "/Rice/Sona Masoori Rice back.webp",
      ],
    },
    // Add more items here with their respective image paths and titles
  ];
  return (
    <div id="blend-masalas">
      <div className="blend-masalas-bg">
        <div className="container-fluid">
          <Header />
          <div className="container blend-masalas-product">
            <div className="blend-page-title text-center">
              <h2 className="h2-large">Rice</h2>
            </div>
            <div className="row">
              {data.map((item) => (
                <div
                  key={item.id}
                  className="col-md-3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div
                    className="card blend-masalas-content"
                    onClick={() => handleShowModal(item.images, item.title)} // Pass title to handleShowModal
                  >
                    <img
                      className="primary-image"
                      src={item.images[0]} // Display only the first image
                      alt={item.title}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{item.title}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ImageModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedImages={selectedImages}
        selectedTitle={selectedTitle} // Pass selectedTitle to ImageModal
      />
    </div>
  );
}

export default Rice;
