import React from "react";

function Farmer() {
  return (
    <section id="banner-3rd-page" data-aos="fade-up" data-aos-duration="1000">
      <div class="container-fluid bg">
        <div class="row">
          <div class="col-md-12 content">
            <div class="">
              <h1 data-aos="fade-up">
                Bringing A Farm To Fork Experience <br /> Making Sustainable
                Sourcing A Reality
              </h1>
              <span class="text-center" data-aos="fade-up">
                Horeb Spices
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Farmer;
