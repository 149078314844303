import React from "react";

function Hero() {
  return (
    <section id="banner-text" data-aos-duration="1000">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="page-title text-center">
              <span className="small-text-1" data-aos="fade-up">
                We Bring
              </span>
              <h1 className="h1-large" data-aos="fade-up">
                Authentic Taste{" "}
              </h1>
              <h2 className="h2-large" data-aos="fade-up">
                To Your Kitchen
              </h2>
              <span className="small-text-2" data-aos="fade-up">
                Horeb spices
              </span>
            </div>
          </div>

          <div className="col-md-12 col-12 col-sm-12">
            <div className="banner-content mt-4" data-aos="fade-up">
              <p>
                Hundreds of flavours, under one roof,
                <br />
                From traditional Indian dishes to fusion cuisine,
                <br />
                Horeb spice will add a burst of flavor to every meal.
                <br />
                Try our products now and taste the difference yourself.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
