import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";
import "../../style/custom.css";
import { Link } from "react-router-dom";

function Footer() {
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-links">
                <div className="footer-info">
                  <h3>Horeb foods</h3>
                  <p>
                    <b>Canada:</b> <br />
                    HOREB BUSINESS SOLUTIONS INC, <br />
                    1325 Tudor Crescent,
                    <br />
                    Peterborough,
                    <br />
                    ON Canada K9L1X1 <br />
                    <br />
                    <strong>Phone:</strong> +1 3433336317 <br />
                    <strong>Email:</strong> &nbsp; care@horebfoods.com <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <div className="footer-info" style={{ marginTop: "40px" }}>
                  <p>
                    <b>India:</b> <br />
                    M/S. HOREB TRADING & EXPORTS PVT LTD, <br />
                    No: 3/300-7, Kalingapatti road, Parankuntrapuram B.O <br />
                    V.K. Pudhur, Tirunelveli -627859,<br></br>
                    Tamil Nadu, India <br />
                    <br />
                    <strong>Phone:</strong> +91 8056569589, +91 8870844506{" "}
                    <br />
                    <strong>Email:</strong> &nbsp; care@horebfoods.com <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <div className="footer-info" style={{ marginTop: "40px" }}>
                  <p>
                    <b>South Africa:</b> <br />
                    HOREB FOOD PROCESSING AND DISTRIBUTORS PVT LTD, <br />
                    Section 26, Door 105, <br />
                    48, Van Riebeeck Avenue, <br />
                    KG Centre, 1 Floor Alberton Central, <br />
                    Alberton, Gauteng, South Africa 1449 <br />
                    <br />
                    <strong>Phone:</strong> +27 10 216 9318, +27 794570601{" "}
                    <br />
                    <br />
                    <strong>Email:</strong> &nbsp; care@horebfoods.com <br />
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful links</h4>
                <ul>
                  <li>
                    <BsChevronRight
                      style={{ color: "yellow" }}
                      className="footer_icon"
                    />
                    <Link to="/" onClick={handleScroll}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <BsChevronRight
                      style={{ color: "yellow", fontWeight: "900" }}
                      className="footer_icon"
                    />
                    <Link to="/about" onClick={handleScroll}>
                      About us
                    </Link>
                  </li>
                  <li>
                    <BsChevronRight
                      style={{ color: "yellow" }}
                      className="footer_icon"
                    />
                    <a
                      href="/Horeb Brochure.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Brochure
                    </a>
                  </li>
                </ul>
                <div className="social-links mb-3">
                  <a
                    href="https://www.youtube.com/@HorebFoods"
                    target="blank"
                    className="youtube"
                  >
                    <FaYoutube />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100094016017105&mibextid=LQQJ4d"
                    className="facebook"
                    target="blank"
                  >
                    <FaFacebook />
                  </a>
                  <a
                    href="https://instagram.com/horebspices"
                    className="instagram"
                    target="blank"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://wa.link/3rku0x "
                    target="blank"
                    className="whatsapp"
                  >
                    <FaWhatsapp />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container footer-bottom">
            <div className="copyright">
              &copy; Copyright <strong>Horeb foods</strong>. All Rights Reserved
            </div>
            <div className="credits">
              <p>
                Designed by <Link to="/">Aang</Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
